var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "w-100 mt-4" },
    [
      _c(
        "div",
        { staticClass: "bg-transparent rounded-lg p-3" },
        [
          _c(
            "b-row",
            { staticClass: "w-100 ml-0", attrs: { "align-v": "center" } },
            [_c("b-col", [_c("h5", [_vm._v("Upload History")])])],
            1
          ),
          _c(
            "b-row",
            {
              staticClass: "w-100 ml-0 justify-content-md-end",
              attrs: { "align-v": "center" }
            },
            [
              _c(
                "b-col",
                { staticClass: "col-md-4" },
                [
                  _c(
                    "b-row",
                    {
                      staticClass: "show-deleted",
                      attrs: { "align-v": "center" }
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "m-0" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "col-md-9 text-center flex-column" },
                            [
                              _c(
                                "span",
                                { staticClass: "show-deleted-label" },
                                [_vm._v("Show deleted matches")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "col-md-1 offset-md-1 text-center" },
                            [
                              _c("b-checkbox", {
                                directives: [
                                  {
                                    name: "test",
                                    rawName: "v-test",
                                    value: "toggle-deleted",
                                    expression: "'toggle-deleted'"
                                  }
                                ],
                                attrs: {
                                  disabled: _vm.showLoading,
                                  id: "toggle-deleted",
                                  size: "lg",
                                  switch: ""
                                },
                                on: { change: _vm.toggleDeleted },
                                model: {
                                  value: _vm.returnDeleted,
                                  callback: function($$v) {
                                    _vm.returnDeleted = $$v
                                  },
                                  expression: "returnDeleted"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "m-0" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "col-md-9 text-center align-self-center"
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "show-deleted-label" },
                                [_vm._v("Refresh upload history")]
                              )
                            ]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "col-md-2 text-center ml-1" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "refresh-btn text-center",
                                  attrs: { variant: "light" },
                                  on: { click: _vm.refreshBatches }
                                },
                                [_c("b-icon-arrow-clockwise")],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3 bg-white card" },
            [
              _c("b-table", {
                attrs: {
                  id: "batches-table",
                  borderless: "",
                  striped: "",
                  small: "",
                  "show-empty": "",
                  stacked: "md",
                  fields: _vm.fields,
                  items: _vm.batchTableProvider,
                  "primary-key": "batchId",
                  "current-page": _vm.currentPage,
                  "per-page": _vm.itemsPerPage,
                  "sort-by": _vm.sortBy,
                  "sort-desc": _vm.sortDesc,
                  "table-class": "upload-history-table",
                  "tbody-tr-class": _vm.getRowClass,
                  "head-variant": "light"
                },
                on: {
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.sortDesc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.sortDesc = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "head()",
                    fn: function(head) {
                      return [
                        _vm._v(" " + _vm._s(head.label) + " "),
                        head.field.sortable
                          ? _c("SortIcon", {
                              staticClass: "mx-1",
                              attrs: {
                                "sort-desc": _vm.sortDesc,
                                active: _vm.sortBy === head.field.key
                              }
                            })
                          : _vm._e(),
                        head.field.tooltip
                          ? _c("b-icon-question-circle", {
                              directives: [
                                {
                                  name: "infinity-tooltip",
                                  rawName: "v-infinity-tooltip",
                                  value: head.field.tooltip,
                                  expression: "head.field.tooltip"
                                }
                              ],
                              staticClass: "text-info ml-1"
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(matchedRows)",
                    fn: function(data) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              data.item.isProcessing
                                ? "Processing"
                                : data.item.getMatchedRows() +
                                    " / " +
                                    data.item.getTotalRows()
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "cell(matchRate)",
                    fn: function(data) {
                      return [
                        _vm._v(" " + _vm._s(data.item.getMatchRate()) + " ")
                      ]
                    }
                  },
                  {
                    key: "cell(isSftpUpload)",
                    fn: function(data) {
                      return [
                        data.item.ftpUpload
                          ? _c("SftpIcon", {
                              directives: [
                                {
                                  name: "infinity-tooltip",
                                  rawName: "v-infinity-tooltip",
                                  value: "SFTP upload",
                                  expression: "'SFTP upload'"
                                }
                              ]
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "cell(button)",
                    fn: function(data) {
                      return [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "infinity-tooltip",
                                rawName: "v-infinity-tooltip",
                                value: _vm.getTooltipMessage(data.item),
                                expression: "getTooltipMessage(data.item)"
                              }
                            ],
                            attrs: { disabled: data.item.isComplete }
                          },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "test",
                                    rawName: "v-test",
                                    value: "trigger-" + data.item.batchId,
                                    expression: "`trigger-${data.item.batchId}`"
                                  }
                                ],
                                ref: "batch-" + data.item.batchId,
                                staticClass: "dropdown-btn",
                                attrs: {
                                  disabled:
                                    !data.item.filename ||
                                    data.item.isDeleted ||
                                    !data.item.isComplete,
                                  hidden:
                                    !data.item.filename || data.item.isDeleted,
                                  id: "batch-" + data.item.batchId,
                                  variant: "button"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.showDropdownMenu(data.item)
                                  }
                                }
                              },
                              [_c("b-icon-three-dots-vertical")],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  },
                  {
                    key: "empty",
                    fn: function() {
                      return [
                        _c(
                          "span",
                          { staticClass: "py-2" },
                          [
                            !_vm.hasError
                              ? [
                                  _vm._v(
                                    "There are no batches to show. Upload a file to get started with Smart Match."
                                  )
                                ]
                              : [
                                  _vm._v(
                                    "We had a problem fetching your batches. Please try again later."
                                  )
                                ]
                          ],
                          2
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c(
                "infinity-popover",
                {
                  directives: [
                    {
                      name: "test",
                      rawName: "v-test",
                      value: "popover",
                      expression: "'popover'"
                    }
                  ],
                  attrs: {
                    target: _vm.menuTarget,
                    show: _vm.showDropdown,
                    triggers: "",
                    customClass: "table-popover"
                  },
                  on: {
                    hidden: _vm.onDropdownHide,
                    clickOutside: _vm.onDropdownHide
                  }
                },
                [
                  _vm.selectedBatch
                    ? _c(
                        "b-list-group",
                        [
                          _c(
                            "b-list-group-item",
                            { on: { click: _vm.viewMatchEntries } },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-1" },
                                  [
                                    _c("b-icon-eye-fill", {
                                      staticClass: "icon icon--size-s"
                                    })
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "col-md-8" }, [
                                  _vm._v(" View match entries ")
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "col-md-1" },
                                  [
                                    _c("b-icon-arrow-right", {
                                      staticClass: "icon icon--size-s"
                                    })
                                  ],
                                  1
                                )
                              ])
                            ]
                          ),
                          _c(
                            "b-list-group-item",
                            {
                              directives: [
                                {
                                  name: "test",
                                  rawName: "v-test",
                                  value: "file download",
                                  expression: "'file download'"
                                }
                              ],
                              attrs: {
                                disabled:
                                  _vm.selectedBatch.isDeleted ||
                                  !_vm.selectedBatch.getFilename()
                              },
                              on: { click: _vm.downloadFile }
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-1" },
                                  [
                                    _c("b-icon-cloud-download", {
                                      staticClass: "icon icon--size-s"
                                    })
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "col-md-10" }, [
                                  _vm._v(" Download match data ")
                                ])
                              ])
                            ]
                          ),
                          _c(
                            "b-list-group-item",
                            {
                              attrs: {
                                disabled:
                                  _vm.selectedBatch.getBatchStatus() !== 200
                              },
                              on: {
                                click: function($event) {
                                  return _vm.selectBatchForDelete(
                                    _vm.selectedBatch
                                  )
                                }
                              }
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-1" },
                                  [
                                    _c("b-icon-trash-fill", {
                                      staticClass: "icon icon--size-s"
                                    })
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "col-md-10" }, [
                                  _vm._v(" Delete match data ")
                                ])
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex w-100 justify-content-center" },
                [
                  _c("b-pagination", {
                    staticClass: "infinity-pagination",
                    attrs: {
                      size: "sm mb-0",
                      "total-rows": _vm.totalItems,
                      "per-page": _vm.itemsPerPage,
                      "aria-controls": "batches-table"
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  staticClass: "text-left text-whale",
                  attrs: { id: "delete-batch-modal", static: "", size: "lg" },
                  scopedSlots: _vm._u([
                    {
                      key: "modal-header",
                      fn: function() {
                        return [_c("h4", [_vm._v("Delete upload")])]
                      },
                      proxy: true
                    },
                    {
                      key: "modal-footer",
                      fn: function() {
                        return [
                          _c(
                            "b-button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.$bvModal.hide("delete-batch-modal")
                                }
                              }
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "danger" },
                              on: { click: _vm.deleteBatches }
                            },
                            [_vm._v(" Delete ")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("p", { staticClass: "modal-text" }, [
                    _vm._v(" You are about to delete this upload. ")
                  ]),
                  _c("b-table", {
                    attrs: {
                      bordered: "",
                      responsive: "",
                      id: "delete-batch-table",
                      fields: _vm.modalFields,
                      items: _vm.deleteTableProvider,
                      "primary-key": "batchId",
                      "table-class": "upload-history-table",
                      "head-variant": "light"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "head()",
                        fn: function(head) {
                          return [
                            _vm._v(" " + _vm._s(head.label) + " "),
                            head.field.sortable
                              ? _c("SortIcon", {
                                  staticClass: "mx-1",
                                  attrs: {
                                    "sort-desc": _vm.sortDesc,
                                    active: _vm.sortBy === head.field.key
                                  }
                                })
                              : _vm._e(),
                            head.field.tooltip
                              ? _c("b-icon-question-circle", {
                                  directives: [
                                    {
                                      name: "infinity-tooltip",
                                      rawName: "v-infinity-tooltip",
                                      value: head.field.tooltip,
                                      expression: "head.field.tooltip"
                                    }
                                  ],
                                  staticClass: "text-info ml-1"
                                })
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "cell(matchedRows)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.item.isProcessing
                                    ? "-"
                                    : data.item.getMatchedRows()
                                ) +
                                " / " +
                                _vm._s(data.item.getTotalRows()) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(matchRate)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  data.item.matchRate
                                    ? data.item.matchRate + "%"
                                    : "-"
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("div", { staticClass: "modal-bg-tan p-3 mb-3" }, [
                    _c("p", [
                      _vm._v(
                        " By deleting this upload, you will delete the data mapped from the CSV file you uploaded. "
                      )
                    ]),
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        " This data will not be retrievable once deleted. "
                      )
                    ])
                  ]),
                  _c("p", { staticClass: "modal-text mb-0" }, [
                    _c("strong", [
                      _vm._v(" Are you sure you want to delete this upload? ")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("b-toaster", { attrs: { name: "b-toaster-bottom-right" } }),
      _vm.showLoading
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "test",
                  rawName: "v-test",
                  value: "infinity-loader",
                  expression: "'infinity-loader'"
                }
              ],
              attrs: { id: "infinity-loader-body" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-content-center",
                  attrs: { id: "infinity-spinner-body" }
                },
                [
                  _c("b-spinner", {
                    attrs: {
                      id: "infinity-spinner",
                      small: "",
                      variant: "light"
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }