








































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  ButtonPlugin,
  TablePlugin,
  BIconArrowClockwise,
  BIconCloudDownload,
  BIconTrashFill,
  BIconEyeFill,
  BIconArrowRight,
  BIconThreeDotsVertical,
  BIconQuestionCircle,
  BIconCheck,
  BSpinner,
  BDropdownDivider,
  ModalPlugin,
  ListGroupPlugin,
  ToastPlugin,
  PaginationPlugin
} from 'bootstrap-vue'
import Batch from '@/models/Batch'
import { formatDate } from '@infinity/shared/utils/time'
import BatchRepo from '@/repos/BatchRepo'
import { AuthUtil } from '@infinity/shared/utils/auth'
import SortIcon from '@/components/SortIcon.vue'
import SftpIcon from '@/components/SftpIcon.vue'
import BatchResponse from '@/models/BatchResponse'
import { drop, isArray, take } from 'lodash'

Vue.use(ListGroupPlugin)
Vue.use(ModalPlugin)
Vue.use(TablePlugin)
Vue.use(ButtonPlugin)
Vue.use(ToastPlugin)
Vue.use(PaginationPlugin)

export enum SortableFields { MatchDatetime = 'matchDatetime', BatchID = 'batchId', StartDatetime = 'startDatetime', EndDatetime = 'endDatetime', Filename = 'filename' }

@Component({
  components: {
    BSpinner,
    BIconArrowClockwise,
    BIconCloudDownload,
    BIconTrashFill,
    BIconEyeFill,
    BIconArrowRight,
    BIconThreeDotsVertical,
    BIconQuestionCircle,
    BIconCheck,
    BDropdownDivider,
    SortIcon,
    SftpIcon
  }
})

export default class UploadHistory extends Vue {
  // Table variables
  private showLoading = false
  private hasError = false

  private fields = [
    { key: 'startDatetime', label: 'Start Date', sortable: true, formatter: this.formatDate },
    { key: 'endDatetime', label: 'End Date', sortable: true, formatter: this.formatDate },
    { key: 'matchDatetime', label: 'Upload Date', sortable: true, formatter: this.formatDate },
    { key: 'matchedRows', label: 'Matched / Total Rows', sortable: true, tooltip: 'The number of rows that were matched and the total number of rows in the uploaded file.' },
    { key: 'matchRate', label: 'Match Rate', sortable: false, tooltip: 'The percentage of Smart Match events that were successfully matched to calls.' },
    { key: 'isSftpUpload', label: '', sortable: false, tooltip: 'SFTP upload' },
    { key: 'button', label: '', sortable: false }
  ]

  private modalFields = [
    { key: 'startDatetime', label: 'Start Date', formatter: this.formatDate },
    { key: 'endDatetime', label: 'End Date', formatter: this.formatDate },
    { key: 'matchDatetime', label: 'Upload Date', formatter: this.formatDate },
    { key: 'matchedRows', label: 'Matched / Total Rows', tooltip: 'The number of rows that were matched and the total number of rows in the uploaded file.' },
    { key: 'matchRate', label: 'Match Rate', tooltip: 'The percentage of Smart Match events that were successfully matched to calls.' }
  ]

  private sortBy = SortableFields.MatchDatetime
  private sortDesc = true

  private returnDeleted = false

  private currentPage = 1
  private itemsPerPage = 20
  private totalItems = 0

  private showDropdown = false
  private selectedBatch: Batch | null = null
  private selectedBatchForDelete: Batch | null = null

  async batchTableProvider () {
    this.showLoading = true
    this.hasError = false

    let items: Batch[] = []
    const batchRepo = new BatchRepo()

    const offset = (this.currentPage - 1) * this.itemsPerPage

    try {
      const response = await batchRepo.getBatches(this.$store.state.installationId, this.returnDeleted, this.itemsPerPage, offset, this.sortBy, this.sortDesc ? 'DESC' : 'ASC')

      if (response instanceof BatchResponse) {
        this.totalItems = response.getTotalBatchCount()
        items = response.getBatches()
      }

      if (isArray(response)) {
        this.totalItems = response.length
        items = take(drop(response, offset), this.itemsPerPage)
      }
    } catch (e) {
      this.hasError = true
    }

    this.showLoading = false

    return items
  }

  deleteTableProvider (): Array<Batch> {
    if (this.selectedBatchForDelete) {
      return [this.selectedBatchForDelete]
    }

    return []
  }

  formatDate (date: string) {
    const timezone = this.$infinityAuth.getInstallation().getTimeZone() !== '' ? this.$infinityAuth.getInstallation().getTimeZone() : 'UTC'
    return formatDate(new Date(date), timezone)
  }

  getRowClass (item: Batch, type: string): string | null {
    if (item && type === 'row') {
      if (item.isDeleted) {
        return 'deleted'
      } else if (item.isReadyToDelete) {
        return 'ready-to-delete'
      }
    }

    return null
  }

  getTooltipMessage (item: Batch): string {
    if (item.hasFailedState) {
      return 'This batch received an error is not processing.'
    } else if (item.isProcessing) {
      return 'The current batch is still processing.'
    } else if (item.isReadyToDelete) {
      return 'The current batch is marked for deletion.'
    }

    return ''
  }

  toggleDeleted () {
    this.returnDeleted = !this.returnDeleted
    this.$root.$emit('bv::refresh::table', 'batches-table')
    this.toggleToaster()
  }

  refreshBatches () {
    this.$root.$emit('bv::refresh::table', 'batches-table')
  }

  toggleToaster () {
    this.$bvToast.hide('infinity-toaster')
    setTimeout(() => {
      const h = this.$createElement
      const vNodesTitle = h(
        'div',
        { class: ['d-flex', 'flex-grow-1', 'align-items-center', 'mr-2'] },
        [
          h('b-icon-check', { class: 'mr-2 icon--size-m' }),
          h('strong', { class: 'mr-2' }, `${this.returnDeleted ? 'Show' : 'Hide'} deleted matches`)
        ])
      this.$bvToast.toast(`Deleted matches will now be ${this.returnDeleted ? 'shown' : 'hidden'} in the table.`, {
        title: [vNodesTitle],
        id: 'infinity-toaster',
        toaster: 'b-toaster-bottom-right',
        toastClass: 'infinity-toaster',
        headerClass: 'infinity-toaster-header',
        bodyClass: 'infinity-toaster-body',
        autoHideDelay: 5000
      })
    }, 500)
  }

  async downloadFile () {
    if (this.selectedBatch) {
      try {
        await this.$store.dispatch('getBatchFile', { filename: this.selectedBatch.getFilename() })

        location.href = this.$store.state.batchFile.getUrl()
      } catch (e) {
        this.$bvToast.toast('We encountered a problem downloading this file. Please try again.', {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 5000
        })
      }

      await this.$mixpanelService.track('settings.smartMatch.uploadHistory.downloadFile', {
        filename: this.selectedBatch.getFilename()
      })
    }

    this.onDropdownHide()
  }

  async deleteBatches () {
    if (this.selectedBatchForDelete) {
      const batchesRepo = new BatchRepo()
      try {
        await batchesRepo.deleteBatches(this.$store.state.installationId, this.selectedBatchForDelete.getBatchId())
      } catch (e) {
        this.$bvToast.toast('We encountered a problem deleting this batch.', {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 5000
        })
      }

      await this.$mixpanelService.track('settings.smartMatch.uploadHistory.deleteBatch', {
        batchId: this.selectedBatchForDelete.getBatchId()
      })

      this.$root.$emit('bv::refresh::table', 'batches-table')
      this.$bvModal.hide('delete-batch-modal')
      this.selectedBatchForDelete = null
    }
  }

  viewMatchEntries () {
    if (this.selectedBatch) {
      const host = window.location.host
      const dateRange = this.getDateRange(this.selectedBatch.getStartDatetime(), this.selectedBatch.getEndDatetime())
      window.location.href = window.location.protocol + '//' +
        host + `/d/a/log/smart-match?crm=eqi~${this.selectedBatch.getBatchId()}~s&${dateRange}&igrp=${AuthUtil.installationId}`
    }

    this.onDropdownHide()
  }

  getDateRange (startDate: string, endDate: string) {
    const start = new Date(startDate)
    start.setDate(start.getDate() - 1)
    const end = new Date(endDate)
    end.setDate(end.getDate() + 1)

    return `dr=${start.toISOString().slice(0, 10)}` +
            `~${end.toISOString().slice(0, 10)}~c`
  }

  get menuTarget (): Vue | null {
    if (this.selectedBatch) {
      return this.$refs[`batch-${this.selectedBatch.getBatchId()}`] as Vue
    }

    return null
  }

  onDropdownHide () {
    this.$nextTick(() => {
      this.showDropdown = false
      this.selectedBatch = null
    })
  }

  onClickOutside () {
    this.onDropdownHide()
  }

  showDropdownMenu (batch: Batch) {
    if (batch === this.selectedBatch) {
      this.onClickOutside()
      return
    }

    this.showDropdown = false
    this.selectedBatch = batch
    this.showDropdown = true
  }

  selectBatchForDelete (batch: Batch) {
    this.selectedBatchForDelete = batch
    this.$root.$emit('bv::refresh::table', 'delete-batch-table')
    this.onDropdownHide()
    this.$bvModal.show('delete-batch-modal')
  }
}

